<template>
  <v-dialog v-model="modalData.dialog" max-width="650px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="handleNew"
      >
        New Department
      </v-btn>
    </template>

    <v-card>
      <v-form v-model="formValid" ref="form">
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="headline">{{ formTitle }} </span>
              <v-btn
                @click="handleCloseModalForm"
                style="float: right; cursor: pointer"
                icon
                color="#232341"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row style="margin: 0">
            <v-spacer></v-spacer>

            <v-col cols="12" sm="3" md="3">
              <v-select
                v-model="selectedLocale"
                :items="languages"
                :id="dynamicID"
              >
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-select
                v-if="statuses.system"
                v-model="formModel.status"
                :items="statuses.companySettings.department"
                label="Status"
                item-text="value"
                item-value="key"
                :id="dynamicID"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="9" md="9">
                <v-text-field
                  v-if="formModel.translations[selectedLocale.lang]"
                  v-model="formModel.translations[selectedLocale.lang].name"
                  :key="selectedLocale.lang + '-name'"
                  label="Department"
                  :rules="nameRules"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseModalForm">
            Cancel
          </v-btn>
          <!-- v-if="permissionCan('create') || (permissionCan('update') && this.editedItem.id)" -->
          <v-btn
            color="blue darken-1"
            text
            @click="handleSaveModalForm"
            v-if="
              (permissionCan('create') && this.modalData.editedIndex === -1) ||
              permissionCan('update')
            "
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import i18nService from "@/core/services/i18n.service.js";
// import {
//   FETCH_CURRENCY,
//   CURRENCY_COLLECTION,
// } from "@/core/services/store/currency.module.js";

const TRANSLATED_ATRIBUTES = ["name"];

export const initialFormData = {
  id: "",
  status: 1,
  translations: {},
  employees: [],
};

export default {
  name: "DepartmentForm",
  props: ["modalData", "statuses", "permissions", "loader"],
  data() {
    return {
      languages: i18nService.languages,
      selectedLocale: i18nService.languages[0],
      formModel: initialFormData,
      formValid: false,
      nameRules: [
        (v) => !!v || "Department is required",
        (v) => v.length > 2 || "Department must be min 3 characters",
      ],
    };
  },
  computed: {
    // ...mapGetters([CURRENCY_COLLECTION]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    formTitle() {
      return this.modalData.editedIndex === -1
        ? "New Department"
        : "Edit Department";
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.formModel = value.editedItem;
        this.setTranslatedAttributes();
      },
    },
  },
  methods: {
    // ...mapActions([FETCH_CURRENCY]),
    handleCloseModalForm() {
      this.$emit("closeModalForm");
    },
    handleSaveModalForm() {
      this.$refs.form.validate();
      if (this.formValid) {
        this.$emit("saveModalForm", Object.assign({}, this.formModel));
      }
    },
    setTranslatedAttributes(newItem = false) {
      if (newItem) {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            this.formModel.translations[language.lang] = {};
            this.formModel.translations[language.lang][attribute] = "";
          });
        });
      } else {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            if (!this.formModel.translations[language.lang]) {
              this.formModel.translations[language.lang] = {};
              this.formModel.translations[language.lang][attribute] = "";
            }
          });
        });
      }
    },
    handleNew() {
      this.setTranslatedAttributes(true);
    },
    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "companySettings.department." + action
      );
    },
  },
  async mounted() {
    // this.fetchCurrency();
  },
};
</script>
